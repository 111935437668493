import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled, { withTheme } from "styled-components";

import SEO from "../components/globals/seo";
import Layout from "../components/containers/layout";
import Section from "../components/containers/section";
import Container from "../components/containers/container";
import Heading from "../components/typography/heading";
import FaqBannerSmall from "../components/banners/faqBannerSmall";
import Link from "../components/links/link";
import Breadcrumb from "../components/globals/breadcrumb";
import Video from "../components/video/video";
import HTMLRenderer from "../utils/htmlRenderer";

const FaqQuestionPage = ({ data, theme }) => {
  // Grabbing data from Prismic
  const {
    uid,
    data: {
      category,
      title,
      faq_answer: { html: answer },
      yt_video_id,
    },
  } = data.allPrismicFaq.edges[0].node;
  const categoryClean = category.replace(`-`, ` `);

  return (
    <Layout navbarPadOnly>
      <SEO
        title="FAQ: question and answer"
        description="A frequently asked question about TopTekkers"
        path={`/faq/${category}/${uid}`}
      />

      <FaqBannerSmall />

      <Section pt={[60, null, 72]}>
        <Container maxWidth="992px">
          <Breadcrumb>
            <Link to="/faq/">FAQ</Link>
            <Link to={`/faq/${category}`}>{categoryClean}</Link>
          </Breadcrumb>

          <Heading mb="36px" maxWidth="none" capitalize>
            {title.text}
          </Heading>
          
          <HTMLRenderer html={answer} />

          {yt_video_id && (
            <Video>
              <iframe
                title="TopTekkers FAQ related video"
                src={`https://www.youtube.com/embed/${yt_video_id}`}
                width="100%"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </Video>
          )}
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query($currentUID: String!) {
    allPrismicFaq(filter: { uid: { eq: $currentUID } }) {
      edges {
        node {
          uid
          data {
            category
            title {
              text
            }
            faq_answer {
              html
            }
            yt_video_id
          }
        }
      }
    }
  }
`;

FaqQuestionPage.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(FaqQuestionPage);
