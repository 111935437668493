import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Box from "./box";

const BannerWrap = styled(Box)`
  position: relative;
  color: ${p => p.setColor};
  background-color: ${p => p.bg};
  border-top: ${p => p.borderTop};
  width: 100%;
`;

const Banner = ({ children, ...rest }) => {
  return <BannerWrap {...rest}>{children}</BannerWrap>;
};

Banner.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default Banner;
