import React from "react"
import PropTypes from "prop-types"
import { withTheme } from "styled-components"

import Container from "../containers/container"
import Banner from "../containers/banner"
import Heading from "../typography/heading"
import Circle from "../globals/circle"

const faqBannerSmall = ({ theme }) => {
  return (
    <Banner bg={theme.yellow} color={theme.white}>
      <Container py="50px">
        <Heading
          as="span"
          mx="auto"
          mb="none"
          textAlign="center"
          color={theme.white}
          display="block;"
        >
          Frequently Asked Questions
        </Heading>

        <Circle
          grow
          bgColor={theme.orange}
          left={[-30, null, -46, -90]}
          bottom={[-30, null, -46, -90]}
        />
        <Circle
          growMid
          bgColor={theme.pink}
          left="10%"
          top={[-30, null, -46]}
        />
        <Circle
          growMid
          bgColor={theme.blue}
          right="16%"
          bottom={[-30, null, -46]}
        />
        <Circle
          grow
          bgColor={theme.red}
          right={[-30, null, -46, -90]}
          top={[-30, null, -46, -90]}
        />
      </Container>
    </Banner>
  )
}

faqBannerSmall.propTypes = {
  theme: PropTypes.object.isRequired,
}

export default withTheme(faqBannerSmall)
