import styled from "styled-components"

// NEEDS AN ACTIVE STYLE ADDING (green color)!!!

const Breadcrumb = styled.div`
  display: block;
  margin-bottom: 30px;

  @media (min-width: ${p => p.theme.screenM}) {
    margin-bottom: 50px;
  }

  a {
    font-size: 14px;
    color: ${p => p.theme.green};
    margin-right: 30px;
    text-transform: uppercase;
    letter-spacing: 1.75px;
  }
  a:last-of-type {
    margin-right: 0;
  }
`

export default Breadcrumb
